.character-drawer {
    .character-image-uploader {

        div.ant-upload-select,
        div.ant-upload-list-item-container,
        .ant-upload {
            width: 200px !important;
        }

        .ant-upload-list-item-image {
            object-fit: cover !important;
        }

    }
}